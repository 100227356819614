import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const ArmingCountdown = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M24 31c2.222 0 4.111-.777 5.666-2.332C31.223 27.113 32 25.226 32 23.005s-.778-4.11-2.334-5.668C28.111 15.779 26.223 15 24 15v1.769c1.744 0 3.218.606 4.423 1.818 1.205 1.211 1.808 2.682 1.808 4.413 0 1.73-.606 3.202-1.818 4.413-1.211 1.212-2.682 1.818-4.413 1.818a5.87 5.87 0 0 1-2.944-.774 6.967 6.967 0 0 1-2.279-2.069l-1.446.989a8.78 8.78 0 0 0 2.887 2.642C21.37 30.673 22.63 31 24 31Zm-7.116-6.031c.383 0 .715-.142.998-.425.284-.283.425-.616.425-.998s-.141-.715-.424-.998a1.387 1.387 0 0 0-1.017-.425c-.395 0-.728.134-.998.402-.271.269-.407.608-.407 1.018 0 .41.135.75.403 1.02.27.27.61.406 1.02.406Zm.985-4.162a1.4 1.4 0 0 0 .998-.405c.284-.27.425-.603.425-.998s-.134-.734-.403-1.017a1.345 1.345 0 0 0-1.02-.425c-.411 0-.75.141-1.02.425a1.426 1.426 0 0 0-.403 1.017c0 .395.142.727.425.998.283.27.616.405.998.405Zm2.823-2.826c.383 0 .715-.142.998-.425.284-.283.425-.616.425-.998 0-.383-.141-.715-.424-.998a1.388 1.388 0 0 0-1.018-.426c-.395 0-.727.135-.998.403-.27.268-.406.607-.406 1.017 0 .41.135.75.404 1.021.268.27.608.406 1.02.406ZM24 41.684c-.182 0-.373-.016-.573-.05a2.939 2.939 0 0 1-.563-.15c-3.935-1.5-7.063-4.044-9.383-7.632C11.161 30.263 10 26.379 10 22.2v-8.566c0-.686.195-1.303.585-1.852.39-.549.896-.947 1.515-1.194l10.769-4A3.32 3.32 0 0 1 24 6.377c.364 0 .741.07 1.131.211l10.769 4c.62.248 1.124.645 1.514 1.194A3.12 3.12 0 0 1 38 13.634V22.2c0 4.18-1.16 8.063-3.481 11.652-2.32 3.588-5.448 6.133-9.383 7.633a2.939 2.939 0 0 1-.563.15c-.2.033-.391.05-.573.05Zm0-1.884c3.467-1.1 6.333-3.3 8.6-6.6 2.267-3.3 3.4-6.967 3.4-11v-8.604c0-.256-.07-.487-.212-.692a1.273 1.273 0 0 0-.595-.461l-10.77-4A1.127 1.127 0 0 0 24 8.364c-.154 0-.295.026-.423.077l-10.77 4a1.273 1.273 0 0 0-.596.462 1.193 1.193 0 0 0-.211.692V22.2c0 4.033 1.133 7.7 3.4 11s5.133 5.5 8.6 6.6Z"
      fill="currentColor"
    />
  </svg>
);

export default ArmingCountdown;
