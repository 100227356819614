import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const EdgesensorHigh = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M4.204 33a.965.965 0 0 1-.712-.288.97.97 0 0 1-.287-.712V22c0-.283.096-.52.288-.712A.969.969 0 0 1 4.207 21c.283 0 .52.096.712.288a.97.97 0 0 1 .286.712v10c0 .283-.096.52-.288.712a.97.97 0 0 1-.713.288Zm5.538-6a.965.965 0 0 1-.712-.288.97.97 0 0 1-.286-.712V16c0-.283.096-.52.288-.713A.969.969 0 0 1 9.745 15c.284 0 .521.096.712.287a.97.97 0 0 1 .287.713v10c0 .283-.096.52-.288.712a.97.97 0 0 1-.714.288Zm7.77 15c-.92 0-1.688-.308-2.305-.925-.617-.617-.925-1.385-.925-2.306V9.231c0-.92.309-1.69.925-2.306.617-.617 1.385-.925 2.306-.925H31.82c.92 0 1.689.308 2.305.925.617.617.925 1.385.925 2.306v29.538c0 .92-.308 1.69-.925 2.306-.616.617-1.385.925-2.305.925H17.513Zm15.54-5h-16.77v1.769c0 .308.128.59.385.846.256.257.538.385.846.385H31.82c.307 0 .59-.128.846-.385.256-.256.384-.538.384-.846V37Zm-16.77-26h16.77V9.231c0-.308-.129-.59-.385-.847C32.41 8.128 32.128 8 31.82 8H17.513c-.308 0-.59.128-.846.384-.257.257-.385.539-.385.847V11Zm23.306 22a.965.965 0 0 1-.712-.288.97.97 0 0 1-.286-.712V22c0-.283.096-.52.288-.712A.97.97 0 0 1 39.59 21c.284 0 .521.096.712.288a.97.97 0 0 1 .287.712v10c0 .283-.096.52-.288.712a.969.969 0 0 1-.714.288Zm5.539-6a.965.965 0 0 1-.712-.288.97.97 0 0 1-.287-.712V16c0-.283.096-.52.288-.713A.97.97 0 0 1 45.13 15c.283 0 .52.096.712.287a.97.97 0 0 1 .286.713v10c0 .283-.096.52-.288.712a.969.969 0 0 1-.713.288Zm-28.845 8h16.77V13h-16.77v22Z"
      fill="currentColor"
    />
  </svg>
);

export default EdgesensorHigh;
