import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const PersonOutline = ({ ...rest }: SvgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 3.933a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8Zm0 6c1.98 0 4.066.973 4.066 1.4v.733H3.934v-.733c0-.427 2.087-1.4 4.067-1.4Zm0-7.266A2.666 2.666 0 1 0 8 8a2.666 2.666 0 1 0 0-5.333Zm0 6c-1.78 0-5.333.893-5.333 2.666v1.333c0 .367.3.667.666.667h9.334c.366 0 .666-.3.666-.667v-1.333C13.333 9.56 9.78 8.666 8 8.666Z"
      fill="currentColor"
    />
  </svg>
);

export default PersonOutline;
