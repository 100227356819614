import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const Stethoscope = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M26.928 42c-3.058 0-5.663-1.068-7.816-3.204-2.152-2.136-3.228-4.734-3.228-7.796v-1.035c-2.533-.235-4.666-1.302-6.4-3.198-1.733-1.896-2.6-4.152-2.6-6.767v-9.384c0-.458.155-.842.465-1.152.31-.31.693-.464 1.15-.464h4.385V8c0-.283.096-.52.288-.713A.97.97 0 0 1 13.886 7c.283 0 .52.096.712.287a.97.97 0 0 1 .286.713v4c0 .283-.096.52-.288.713a.968.968 0 0 1-.713.287.965.965 0 0 1-.712-.287.97.97 0 0 1-.287-.713v-1h-4v9c0 2.2.784 4.083 2.35 5.65 1.567 1.567 3.45 2.35 5.65 2.35s4.084-.783 5.65-2.35c1.567-1.567 2.35-3.45 2.35-5.65v-9h-4v1c0 .283-.096.52-.288.713a.968.968 0 0 1-.713.287.965.965 0 0 1-.712-.287.97.97 0 0 1-.287-.713V8c0-.283.096-.52.288-.713A.97.97 0 0 1 19.886 7c.283 0 .52.096.712.287a.97.97 0 0 1 .286.713v1h4.385c.458 0 .841.155 1.15.464.31.31.465.694.465 1.152V20c0 2.615-.866 4.871-2.6 6.767-1.733 1.896-3.866 2.963-6.4 3.198V31c0 2.5.88 4.625 2.637 6.375C22.278 39.125 24.412 40 26.923 40c2.5 0 4.625-.875 6.375-2.625S35.923 33.5 35.923 31v-3.119a4.139 4.139 0 0 1-2.317-1.492 4.098 4.098 0 0 1-.914-2.62c0-1.175.412-2.174 1.235-2.996.824-.823 1.824-1.235 3-1.235 1.177 0 2.175.412 2.996 1.235.82.822 1.23 1.821 1.23 2.996 0 .98-.304 1.853-.913 2.62a4.141 4.141 0 0 1-2.317 1.492V31c0 3.062-1.068 5.66-3.204 7.796S29.986 42 26.928 42Zm9.995-16a2.15 2.15 0 0 0 1.579-.652 2.15 2.15 0 0 0 .652-1.579 2.15 2.15 0 0 0-.652-1.578 2.15 2.15 0 0 0-1.58-.652 2.15 2.15 0 0 0-1.578.651 2.15 2.15 0 0 0-.652 1.579c0 .618.217 1.144.652 1.579a2.15 2.15 0 0 0 1.579.652Z"
      fill="currentColor"
    />
  </svg>
);

export default Stethoscope;
