import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const QuickPhrases = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M19.616 37.97c-.406 0-.776-.147-1.112-.441-.336-.294-.504-.69-.504-1.187v-4.354h-.346c-3.187-.061-5.927-1.133-8.217-3.215C7.146 26.691 6 24.1 6 21c0-3.07 1.066-5.664 3.198-7.783C11.33 11.098 13.931 10.04 17 10.04h4.081l-3.492-3.493a.999.999 0 0 1-.3-.682c-.014-.268.09-.513.311-.733.213-.213.448-.32.707-.32.26 0 .495.107.709.32l4.777 4.777c.174.174.296.352.367.535.07.181.105.38.105.596 0 .215-.035.414-.105.595-.07.182-.193.36-.367.535l-4.777 4.777c-.188.187-.415.29-.683.306-.268.017-.512-.085-.733-.306-.213-.213-.319-.449-.319-.708 0-.258.106-.494.319-.707l3.481-3.492H17c-2.5 0-4.625.868-6.375 2.605S8 18.5 8 21c0 2.577.984 4.721 2.952 6.433C12.92 29.143 15.231 30 17.884 30H20v5.389L25.35 30H31c2.5 0 4.631-.875 6.394-2.625S40.039 23.5 40.039 21s-.882-4.625-2.645-6.375S33.5 12 31 12a.968.968 0 0 1-.713-.287A.968.968 0 0 1 30 11c0-.285.096-.523.287-.713A.968.968 0 0 1 31 10c3.07 0 5.676 1.066 7.821 3.198 2.145 2.132 3.218 4.733 3.218 7.802 0 3.07-1.073 5.67-3.218 7.802C36.676 30.934 34.069 32 31 32h-4.769l-5.485 5.484a1.588 1.588 0 0 1-1.13.485Z"
      fill="currentColor"
    />
  </svg>
);

export default QuickPhrases;
