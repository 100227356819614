import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const Lock = ({ ...rest }: SvgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.997 14.667c-.366 0-.68-.13-.941-.392a1.284 1.284 0 0 1-.392-.941V6.667c0-.367.13-.68.392-.942.26-.26.575-.391.941-.391h.667V4c0-.922.325-1.708.975-2.358A3.213 3.213 0 0 1 7.997.667c.923 0 1.709.325 2.359.975.65.65.975 1.436.975 2.358v1.334h.666c.367 0 .681.13.942.391.261.261.392.575.392.942v6.667c0 .366-.13.68-.392.941a1.284 1.284 0 0 1-.942.392h-8Zm4-3.333c.367 0 .68-.13.942-.392.261-.261.392-.575.392-.942 0-.366-.13-.68-.392-.941a1.284 1.284 0 0 0-.942-.392c-.366 0-.68.13-.941.392a1.284 1.284 0 0 0-.392.941c0 .367.13.68.392.942.26.261.575.392.941.392Zm-2-6h4V4c0-.555-.194-1.027-.583-1.416A1.929 1.929 0 0 0 7.997 2c-.555 0-1.027.195-1.416.584A1.929 1.929 0 0 0 5.997 4v1.334Z"
      fill="currentColor"
    />
  </svg>
);

export default Lock;
