import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const ForYou = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M24 24.77a47.38 47.38 0 0 0 6.27-.43 38.712 38.712 0 0 0 6.096-1.301c.512-.19.99-.114 1.432.227.442.34.664.787.664 1.338v12.954c0 .449-.128.846-.382 1.193a2.132 2.132 0 0 1-.987.75 39.981 39.981 0 0 1-6.414 1.628 39.995 39.995 0 0 1-6.679.564c-2.23 0-4.457-.188-6.679-.564a39.978 39.978 0 0 1-6.413-1.629 2.131 2.131 0 0 1-.987-.749 1.963 1.963 0 0 1-.382-1.193V24.604c0-.551.22-.997.663-1.338.442-.341.92-.417 1.433-.227a38.71 38.71 0 0 0 6.096 1.302c2.141.285 4.23.428 6.27.428Zm12.462 12.769V25.116c-1.59.466-3.573.859-5.948 1.177-2.376.317-4.547.476-6.514.476-1.967 0-4.138-.159-6.513-.476-2.376-.318-4.359-.71-5.948-1.177v12.423c1.512.6 3.467 1.109 5.865 1.527 2.397.418 4.596.627 6.596.627s4.199-.21 6.596-.627c2.398-.418 4.353-.927 5.866-1.527ZM24 6.308c1.777 0 3.298.632 4.564 1.898 1.265 1.265 1.898 2.786 1.898 4.563 0 1.777-.633 3.298-1.898 4.564-1.266 1.265-2.787 1.898-4.564 1.898-1.777 0-3.298-.633-4.563-1.898-1.266-1.266-1.898-2.787-1.898-4.564 0-1.777.632-3.298 1.898-4.563C20.702 6.94 22.223 6.308 24 6.308Zm.002 10.923c1.227 0 2.277-.437 3.15-1.31.873-.875 1.31-1.926 1.31-3.153s-.437-2.277-1.311-3.15c-.874-.874-1.925-1.31-3.152-1.31-1.228 0-2.278.437-3.15 1.31-.874.875-1.31 1.925-1.31 3.153 0 1.227.437 2.277 1.31 3.15.875.873 1.925 1.31 3.153 1.31Z"
      fill="currentColor"
    />
  </svg>
);

export default ForYou;
