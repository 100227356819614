import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const AnimatedImages = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m27.523 23.796 5.092-3.453A1.54 1.54 0 0 0 33.323 19a1.54 1.54 0 0 0-.708-1.343l-5.092-3.453c-.538-.39-1.094-.423-1.666-.1-.571.323-.857.808-.857 1.454v6.884c0 .646.286 1.131.857 1.454.572.323 1.128.29 1.666-.1Zm-9.216 9.127c-.92 0-1.688-.308-2.305-.925-.617-.617-.925-1.385-.925-2.306V9.232c0-.92.308-1.69.925-2.306.617-.617 1.385-.925 2.305-.925H38.77c.92 0 1.69.308 2.306.925.616.617.925 1.385.925 2.306v20.462c0 .92-.309 1.688-.925 2.305-.617.617-1.386.925-2.306.925H18.307Zm0-2H38.77c.359 0 .654-.115.885-.346.23-.23.346-.526.346-.885V9.232c0-.359-.115-.654-.346-.885-.231-.23-.526-.346-.885-.346H18.307c-.359 0-.653.115-.884.346-.23.231-.346.526-.346.885v20.462c0 .358.115.653.346.884.23.23.525.346.884.346Zm-5.946 10.712c-.895.107-1.694-.108-2.398-.646-.704-.539-1.11-1.255-1.217-2.15L6.288 19.412c-.107-.895.118-1.706.677-2.431.56-.726 1.286-1.134 2.18-1.224l.87-.038c.285-.023.537.056.756.239.22.182.329.432.329.75a1 1 0 0 1-.256.665.905.905 0 0 1-.621.334l-.78.039a1.113 1.113 0 0 0-.847.443c-.205.269-.282.583-.23.942l2.391 19.438c.052.359.206.641.462.846.257.206.564.282.923.231l23.546-2.938a.954.954 0 0 1 .75.188c.216.167.344.392.385.677.04.285-.022.526-.189.725a1.042 1.042 0 0 1-.677.36l-23.596 2.977Z"
      fill="currentColor"
    />
  </svg>
);

export default AnimatedImages;
