import type { ComponentProps } from "react";

type SvgProps = ComponentProps<"svg">;

const LocalLibrary = ({ ...rest }: SvgProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 34.146V19.577c0-.605.224-1.102.671-1.489.448-.387.977-.547 1.587-.48 2.582.4 5.026 1.174 7.332 2.325A29.785 29.785 0 0 1 24 24.254a29.785 29.785 0 0 1 6.41-4.322 24.858 24.858 0 0 1 7.332-2.324c.61-.067 1.139.093 1.587.48.447.387.671.884.671 1.489v14.569c0 .528-.177.988-.531 1.379a2.08 2.08 0 0 1-1.323.687c-2.338.358-4.514.915-6.527 1.669s-3.988 1.79-5.927 3.108a2.695 2.695 0 0 1-.812.425 2.968 2.968 0 0 1-.883.128c-.31 0-.604-.043-.88-.129a2.702 2.702 0 0 1-.81-.424C20.37 39.67 18.4 38.635 16.4 37.88s-4.182-1.31-6.546-1.67a2.08 2.08 0 0 1-1.323-.686A1.988 1.988 0 0 1 8 34.146Zm16 5.577a30.218 30.218 0 0 1 6.7-3.728 29.077 29.077 0 0 1 7.3-1.872V19.554a24.57 24.57 0 0 0-7.483 2.529A26.984 26.984 0 0 0 24 26.916a26.984 26.984 0 0 0-6.517-4.834A24.57 24.57 0 0 0 10 19.555v14.569c2.5.304 4.933.928 7.3 1.872 2.367.944 4.6 2.187 6.7 3.728Zm0-22.569c-1.777 0-3.298-.633-4.564-1.898-1.265-1.266-1.898-2.787-1.898-4.563 0-1.778.633-3.299 1.898-4.564C20.703 4.864 22.223 4.231 24 4.231c1.777 0 3.298.633 4.564 1.898 1.265 1.265 1.898 2.786 1.898 4.564 0 1.776-.633 3.297-1.898 4.563-1.266 1.265-2.787 1.898-4.564 1.898Zm.002-2c1.227 0 2.277-.437 3.15-1.311.873-.874 1.31-1.925 1.31-3.152s-.438-2.278-1.311-3.15c-.875-.874-1.925-1.31-3.152-1.31-1.228 0-2.278.437-3.151 1.31-.873.874-1.31 1.925-1.31 3.152 0 1.228.438 2.278 1.311 3.151.875.873 1.925 1.31 3.152 1.31Z"
      fill="currentColor"
    />
  </svg>
);

export default LocalLibrary;
